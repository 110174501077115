import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';

import base from './base.css'
import Container from './container'
import Nav from './nav'
import Footer from './footer'
import { Helmet } from 'react-helmet'
import { ParallaxProvider } from 'react-scroll-parallax';


class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <React.Fragment>
      <Helmet title="nLight Design">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"/>
      </Helmet>  
      <ParallaxProvider>
      <div className="content">       
        <Nav />
        {children}
        <Footer/>
      </div>
      </ParallaxProvider>
      </React.Fragment>

    )
  }
}

export default Template
