import React from 'react'
import logo from '../assets/images/logoWhite.png'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Footer = () => {
    return(
    <Row className="space" id="footer">
        <Col>
        <Container>
            <Row>
                <Col>
                <img src={logo}/>
                <p>©2020 All Rights Reserved</p>
                </Col>
            </Row>
        </Container>
        </Col>
    </Row>
    )
}

export default Footer