import React, { useContext } from 'react'
import logo from '../assets/images/logo.png'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import IntakeFormContext from '../context/intake-form-context'


const Navigation = () => {



  return (
    <Navbar expand="lg">
      <Container>
      <Navbar.Brand href="/"><img src={logo} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link href="#">Design Assessment</Nav.Link>
          <Nav.Link href="/portfolio">Our Work</Nav.Link>
          <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation